<script setup>
const { locales, locale } = useI18n();
const switchLocalePath = useSwitchLocalePath();
</script>

<template>
  <div class="desktop flex">
    <div
      v-for="(language, index) in locales"
      :key="index"
      class="flex align-center"
    >
      <p class="language">
        <NuxtLink :key="language.code" :to="switchLocalePath(language.code)">
          <span :class="{ active: language.code === locale }">{{
            language.name
          }}</span></NuxtLink
        >
      </p>
      <span v-if="index == 0"> | </span>
    </div>
  </div>
</template>

<style>
.menu__items li,
.language {
  text-transform: uppercase;
  letter-spacing: 0.8px;
  a {
    text-decoration: none;
    position: relative;
    &:hover {
      transition-duration: 350ms;
    }
    &:hover::after {
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 1px;
      bottom: -1px;
      left: 0;
      background-color: currentColor;
      transition: width 0.3s ease-out;
    }
    &:hover::after {
      width: 100%;
    }
  }
  .active,
  .active.a,
  .active a {
    font-weight: bold;
    cursor: default;
    &::after {
      content: none;
    }
  }
}
.language {
  padding: 0 0.25rem;
}
</style>
