<template>
  <Header />
  <NuxtPage />
  <Footer />
</template>

<style scoped>
@import url(/assets/styles/colors.scss);
@import url(/assets/styles/typography.scss);
@import url(/assets/styles/variables.scss);
</style>
