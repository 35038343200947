<script setup>
const { t, locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const props = defineProps(["menuItems"]);

const isMenuOpen = ref(false);
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

watch(isMenuOpen, (newValue) => {
  document.body.style.overflow = newValue ? "hidden" : "";
});

onUnmounted(() => {
  document.body.style.overflow = "";
});
</script>

<template>
  <div class="menu__hamburger mobile" @click="toggleMenu">
    <div :class="{ bar: true, active: isMenuOpen }"></div>
    <div :class="{ bar: true, active: isMenuOpen }"></div>
    <div :class="{ bar: true, active: isMenuOpen }"></div>
  </div>
  <div v-if="isMenuOpen" class="menu__overlay" @click="toggleMenu">
    <div class="menu__overlay-bg">
      <div class="menu__overlay-items">
        <div></div>
        <div class="text-links flex-column">
          <p v-for="item in menuItems" :key="item.link">
            <NuxtLink :to="localePath(item.link)">
              {{ item.text }}
            </NuxtLink>
          </p>
        </div>

        <div class="menu__overlay-social text-links">
          <p class="language" v-for="(locale, index) in locales">
            <NuxtLink :key="locale.code" :to="switchLocalePath(locale.code)">
              <span>{{ locale.name }}</span></NuxtLink
            >
            <span v-if="index == 0"> |&nbsp;</span>
            <!-- Add a space after the pipe -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.menu__hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  cursor: pointer;
  z-index: 4;
  @media (max-width: 970px) {
    display: flex;
  }
  .bar {
    width: 36px;
    height: 1px;
    background-color: white;
    transition: all 0.3s ease;
  }
  .bar.active:nth-child(1) {
    transform: rotate(45deg) translate(11px, 5px);
  }
  .bar.active:nth-child(2) {
    opacity: 0;
  }
  .bar.active:nth-child(3) {
    transform: rotate(-45deg) translate(11px, -5px);
  }
}
.menu__overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url("/images/bg/gradient_pink.webp");
  background-size: cover;
  background-position: center center;
  transition-duration: 350ms;
}
.menu__overlay-bg {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 1.5vw;
}
.menu__overlay-items {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  .text-links {
    display: flex;
    p {
      text-decoration: none;
      font-size: 4vw;
      text-transform: uppercase;
      a {
        text-decoration: none;
        font-size: 4vw;
        text-transform: uppercase;
      }
    }
  }
}
.flex-column {
  flex-direction: column;
}

.menu__overlay-social img {
  margin-right: 24px;
}
</style>
