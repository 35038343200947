<script setup>
const { t } = useI18n();
const sections = [
  {
    title: t("footer.downloads.title"),
    items: [
      {
        text: "Tisková zpráva",
        link: "https://assets.zyrosite.com/dJoqB7bg2QC9aO5Q/tz-art-vr-AGB31KyVNVT70Plm.pdf",
      },
      {
        text: "Fotografie k projektům",
        link: "https://drive.google.com/drive/folders/1C-20ydvTn3vlYDRLqmkM9G2DUvv0V9sG?usp=sharing",
      },
      {
        text: "Videa k projektům",
        link: "https://drive.google.com/drive/folders/1qSvnfqf7GDEgoE5j02TafSlnV1edyvOf?usp=sharing",
      },
    ],
  },
  {
    title: t("footer.media.title"),
    items: [
      { text: "Karolína Kočí" },
      { text: "Karolina.koci@dox.cz", link: "mailto:karolina.koci@dox.cz" },
      { text: "+420 777 870 219", link: "tel:+420777870219" },
    ],
  },
  {
    title: t("footer.follow.title"),
    items: [
      { text: "Instagram", link: "https://www.instagram.com/artvrfestival/" },
      { text: "Facebook", link: "https://www.facebook.com/artvrfilmfestival/" },
      { text: "LinkedIn", link: "https://www.linkedin.com/company/artvr" },
    ],
  },
];
</script>

<template>
  <div class="line"></div>
  <div class="grid">
    <div class="w-33 w-m-100">
      <div class="mw-10 footer-logo">
        <img :src="`/images/logo/art_vr.svg`" />
      </div>
    </div>
    <div class="w-66 w-m-100 grid">
      <div v-for="(section, index) in sections" :key="index" class="w-33">
        <h4>{{ section.title }}</h4>
        <p v-for="(item, itemIndex) in section.items" :key="itemIndex">
          <LinkExternal v-if="item.link" :item="item" />
          <span v-else>{{ item.text }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.footer-logo {
  margin: 0;
  margin-top: 0.5vw;
  max-width: 12rem;
}
</style>
